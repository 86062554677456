import * as Styled from './styles';
import { EVENTS } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommunitiesLoading, selectSelectedEvent, setSelectedEvent } from '../../communitiesSlice';
import { useCallback, useEffect, useState } from 'react';
import { ReactComponent as ArrowDown } from '../../../../icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../../icons/arrow-up.svg';
import Spinner from '../../../../components/common/spinner';
import { getUserVerifications } from '../../../../app/api';

const allOptions = [
	{ value: null, label: 'All events' },
	{ value: EVENTS.ZUVILLAGE_GEORGIA, label: 'ZuVillage Georgia' },
	{ value: EVENTS.ALEPH, label: 'Aleph' },
	{ value: EVENTS.ETH_SAFARI, label: 'ETHSafari' },
	{ value: EVENTS.MCONIII, label: 'MCON III' },
]

export default function SelectEvent({ className }) {
	const dispatch = useDispatch();
	const communitiesLoading = useSelector(selectCommunitiesLoading);
	const selectedEvent = useSelector(selectSelectedEvent);
	const [showDropdown, setShowDropdown] = useState(false);
	const [options, setOptions] = useState([allOptions[0]]);

	function selectEvent(option) {
		setShowDropdown(false);
		if (option === selectedOption) return;
		dispatch(setSelectedEvent(option.value))
	}

	//hide dropdown on outside click
	const handleClickOutside = useCallback(event => {
		if (showDropdown && !event.target.closest(`${Styled.Container}`)) {
			setShowDropdown(false);
		}
	}, [showDropdown]);

	const updateOptions = useCallback(async () => {
		const verifications = await getUserVerifications();
		setOptions([
			allOptions[0],
			...verifications.map(verification => allOptions.find(option => option.value === verification))
		]);
	}, []);
	
	const selectedOption = allOptions.find(option => option.value === selectedEvent);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => document.removeEventListener('click', handleClickOutside);
	}, [handleClickOutside]);

	useEffect(() => {
		updateOptions();
	}, [updateOptions]);

	return (
		<Styled.Container className={className}>
			{communitiesLoading && <Spinner />}
			<Styled.Content
				$eventSelected={selectedOption !== options[0]}
				onClick={() => showDropdown ? setShowDropdown(false) : setShowDropdown(true)}
			>
				{selectedOption.value ? selectedOption.label : 'Choose event...'} {showDropdown ? <ArrowUp /> : <ArrowDown />}
			</Styled.Content>
			{showDropdown &&
				<Styled.Options>
					{options.map((option, index) => (
						<Styled.Option key={index} onClick={() => selectEvent(option)}>
							{option?.label}
						</Styled.Option>
					))}
				</Styled.Options>
			}
		</Styled.Container>
	);
}

